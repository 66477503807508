import React, { useState } from "react";

import Modal from "react-modal";
import "../style/Summary.css";
import Pentagon from "./Pentagon";
import Questions from "../questions/Questions";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import emailjs from "emailjs-com";
import { Link } from "react-router-dom";
import { useEffect } from "react";
import newImage from "../images/report.jpg";
import lastImage from "../images/report2.jpg";

import Histogram from "./Histogram";
import Download from "./Download";




function getRandomRecommendations(recommendationsString, count) {
  const recommendationsArray = recommendationsString.split('\n');
  const randomRecommendations = [];

  while (randomRecommendations.length < count && recommendationsArray.length > 0) {
    const randomIndex = Math.floor(Math.random() * recommendationsArray.length);
    const randomRecommendation = recommendationsArray.splice(randomIndex, 1)[0];
    randomRecommendations.push(randomRecommendation);
  }

  return randomRecommendations;
}


export default function Summary() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isDownload, setIsDownload] = useState(false);




  const openDownload = () => {
    setIsDownload(!isDownload);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleConfirmClose = () => {
    setIsModalOpen(false);
    window.location.pathname = "/second";
  };

  function pdfConvert() {
    const doc = new jsPDF({
      orientation: "portrait",
      unit: "mm",
      format: "a4",
      marginLeft: 20,
      marginRight: 20,
      marginTop: 10,
      marginBottom: 25,
    });

    const element = document.getElementById("to-download");
    const title = "teqnia Information Security Assessment tool";

    html2canvas(element).then((canvas) => {
      const pageWidth = doc.internal.pageSize.getWidth();
      const pageHeight = doc.internal.pageSize.getHeight();
      const scaleFactor = pageWidth / canvas.width;
      const imgWidth = pageWidth - 25;
      const imgHeight = canvas.height * scaleFactor - 2;
      const totalPages = Math.ceil(imgHeight / pageHeight);
      const newImageWidth = 190;
      const newImageHeight = 300;
      const lastImageWidth = 190;
      const lastImageHeight = 300;
      let position = 5;

      doc.addImage(
        newImage,
        "PNG",
        (pageWidth - newImageWidth) / 2,
        (pageHeight - newImageHeight) / 2 + 10,
        newImageWidth,
        newImageHeight
      );

      doc.addPage();
      for (let i = 1; i <= totalPages; i++) {
        doc.setDrawColor(0);
        doc.setFillColor(255, 255, 255);
        doc.rect(0, 0, pageWidth, pageHeight, "F");

 
        if (i === totalPages) {
          doc.addImage(
            lastImage,
            "PNG",
            (pageWidth - lastImageWidth) / 2,
            (pageHeight - lastImageHeight) / 2 + 10,
            lastImageWidth,
            lastImageHeight
          );
        }
        doc.addImage(
          canvas.toDataURL("image/png"),
          "PNG",
          13,
          position + 10,
          imgWidth,
          imgHeight,
          undefined,
          "FAST"
        );

     
        doc.setFillColor(255, 255, 255);
        doc.rect(10, pageHeight - 9, pageWidth - 20, pageHeight - 60, "F");

     
        doc.setFillColor(255, 255, 255);
        doc.rect(10, -60, pageWidth - 20, 68.7, "F");

       

        addFooter(doc, i + 1, totalPages + 1);
        position -= pageHeight - 20;

        if (i === totalPages) {
          break;
        }
        doc.addPage();
      }

      doc.save(title);
      setIsModalOpen(false);
    });
  }
  function addHeader(doc, title) {
    doc.setFontSize(10);
    doc.setTextColor(0, 0, 0);
    doc.text(title, 20, 15);
  }

  function addFooter(doc, currentPage, totalPages) {
    doc.setFontSize(10);
    doc.setTextColor(128);
    doc.text(
      `Page ${currentPage} of ${totalPages}`,
      doc.internal.pageSize.getWidth() - 10,
      doc.internal.pageSize.getHeight() - 5,
      {
        align: "right",
      }
    );
  }

  const handleClick = () => {
    setIsModalOpen(true);
  };
  let a = 0;
  const [answer, setAnswer] = useState([]);
  useEffect(() => {
    const storedAnswer = sessionStorage.getItem("answer");
    const parsedAnswer = JSON.parse(storedAnswer);
    setAnswer(parsedAnswer);
  }, []);
  let ans = answer.map((val) => parseInt(val));


  const categoryScores = {};
  ans.forEach((response, index) => {
    const question = Questions[index];
    const weightage = response; 
    const category = question.category;
  
    if (!categoryScores[category]) {
      categoryScores[category] = 0;
    }
  
    categoryScores[category] += weightage;
  });
  


  const categoryMaxScores = {};
  for (const category in categoryScores) {
    categoryMaxScores[category] = 5 * Questions.filter(question => question.category === category).length;
  }



  const categoryPercentages = {};
  for (const category in categoryScores) {
    categoryPercentages[category] = (categoryScores[category] / categoryMaxScores[category]) * 100;
  }


  const lowestTwoCategories = Object.keys(categoryPercentages)
  .sort((a, b) => categoryPercentages[a] - categoryPercentages[b])
  .slice(0, 2);

  

  const recommendations = {};
  for (const category of lowestTwoCategories) {
    const question = Questions.find(q => q.category === category);
    recommendations[category] = question.recomendation; 
  }

 
  const randomRecommendations = {};

  for (const category of lowestTwoCategories) {
    const categoryQuestions = Questions.filter(question => question.category === category);
    const allCategoryRecommendations = categoryQuestions.map(question => question.recomendation);
    const randomRecs = getRandomRecommendations(allCategoryRecommendations.join('\n'), 2);
    randomRecommendations[category] = randomRecs;
  }


  
  
  
  
  


  const handleSubmit = (e) => {
    e.preventDefault();

    const serviceID = `${process.env.REACT_APP_EMAILJS_SERVICE_ID }`;
    const templateID = `${process.env.REACT_APP_EMAILJS_TEMPLATE_ID}`;
    const templateID2 = `${process.env.REACT_APP_EMAILJS_TEMPLATE_ID2}`;
    const userID = `${process.env.REACT_APP_EMAILJS_USER_ID}`;

    const firstName = e.target.firstName?.value || "";
    const lastName = e.target.lastName?.value || "";
    const email = e.target.email?.value || "";
    const mobile = e.target.mobile?.value || "";

    const confirmation = {
      from_name: `Technia team`,
      to_name: `${firstName} ${lastName}`,
      subject: "Contact Form Submission",
      to_mail: `${email}`,
      message: `Dear ${firstName},
      Thank you for completing the assessment, and we hope it was helpful. Our team will reach out to you separately through email.
      Meanwhile, please feel free to connect with us.
      
      Thank you.
      
      Regards,
      Admin, Teqnia.ai
      Bangalore, India.
      +91 90 2005 0050`,
    };

    const toTechnia = {
      from_name: `${firstName} ${lastName}`,
      to_name: "Teqnia Team",
      subject: "Contact Form Submission",
      message: `Form Details:
      First Name: ${firstName}
      Last Name: ${lastName}
      Email: ${email}
      mobile: ${mobile}`,
    };

    Promise.all([
      emailjs.send(serviceID, templateID, confirmation, userID),
      emailjs.send(serviceID, templateID2, toTechnia, userID),
    ])
      .then((responses) => {
        console.log("Emails sent successfully:", responses);
        e.target.reset();
      })
      .catch((error) => {
        console.error("Failed to send emails:", error);
      });
  };

  return (
    <div>
      {/* {download modal} */ isDownload && <Download openDownload={openDownload} />}
      {/* Modal */}
      <Modal
        class="modal-container"
        style={{
          overlay: {
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: "rgba(0, 0, 0, 0.6)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            alignContent: "center",
            zIndex: 9999,
          },
          content: {
            position: "relative",
            backgroundColor: "#1b2d61",
            borderRadius: "20px",
            padding: "20px",

            color: "white",
            bottom: "300px",
            maxWidth: "400px",
            width: "100%",
            maxHeight: "400px",
            overflow: "auto",
          },
        }}
        onRequestClose={handleCloseModal}
        contentLabel="Confirmation Modal"
        closeTimeoutMS={200}
        isOpen={isModalOpen}
        shouldCloseOnOverlayClick={true}
      >
        <h2
          className="modal-title"
          style={{ padding: "20px", textAlign: "center" }}
        >
          Attention
        </h2>
        <p className="modal-message" style={{ textAlign: "center" }}>
          Have you downloaded the report? Your assessment results will disappear
          when you click close.
        </p>
        <div className="modal-buttons" style={{ padding: "20px" }}>
          <button
            className="modal-close btn-summary"
            style={{ width: "150px", height: "40px" }}
            onClick={handleConfirmClose}
          >
            Close
          </button>
          <button
            className="modal-download btn-summary"
            style={{ width: "150px", height: "40px", marginLeft: "50px" }}
            onClick={pdfConvert}
          >
            Download
          </button>
        </div>
      </Modal>
      <body>
        <main className="body-summary" style={{ background: "white" }}>
          <div className="container">
            
            <span>
              <button className="right-summary" onClick={openDownload}>
              Download<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M13 10H18L12 16L6 10H11V3H13V10ZM4 19H20V12H22V20C22 20.5523 21.5523 21 21 21H3C2.44772 21 2 20.5523 2 20V12H4V19Z"></path></svg>
              </button>
            </span>
            <span className="close-btn" >
            <svg className="icon-x" xmlns="http://www.w3.org/2000/svg" onClick={handleClick} viewBox="0 0 24 24">
            <path d="M12.0007 10.5865L16.9504 5.63672L18.3646 7.05093L13.4149 12.0007L18.3646 16.9504L16.9504 18.3646L12.0007 13.4149L7.05093 18.3646L5.63672 16.9504L10.5865 12.0007L5.63672 7.05093L7.05093 5.63672L12.0007 10.5865Z"></path>
          </svg>
            </span>
          </div>

          <div
            id="to-download"
            style={{ overflow: "hidden", width: "inherit" }}
          >
            <div>
              <h1
                
                style={{
                  padding: "10px",
                  fontSize: "44px",
                  textAlign: "center",
                }}
              >
                Summary{" "}
              </h1>

              <div>
                <h1>1. Visualization</h1>
              </div>
              <div>
                <p style={{ fontSize: "16px" }}>
                  Below graph depicts the summary of the profile based on the
                  assessment
                </p>
              </div>

              <div className="centered-container polygons">
                <div style={{ marginLeft: "40px" }}>
                  <Pentagon />
                </div>
                <div>
                  <Histogram />
                </div>
              </div>

              
            </div>
            <div>
              <div>
                <h1>2. Questions and Responses</h1>
              </div>

              <div>
                <ul>
                  {answer.map((value, index) => {
                    return (
                      <li
                        style={{
                          listStyle: "circle",
                          fontSize: "16px",
                          lineHeight: "20px",
                          marginBottom: "10px",
                        }}
                        key={index}
                      >
                        {index + 1}. {Questions[index].question}
                        {value === "1" && (
                          <p
                            style={{
                              fontWeight: " bold",
                              fontSize: "16px",
                              padding: "5px",
                            }}
                          >
                            Does Not Exist
                          </p>
                        )}
                        {value === "2" && (
                          <p
                            style={{
                              fontWeight: "bold",
                              fontSize: "16px",
                              padding: "5px",
                            }}
                          >
                            In Development
                          </p>
                        )}
                        {value === "3" && (
                          <p
                            style={{
                              fontWeight: "bold",
                              fontSize: "16px",
                              padding: "5px",
                            }}
                          >
                            Implemented
                          </p>
                        )}
                        {value === "4" && (
                          <p
                            style={{
                              fontWeight: " bold",
                              fontSize: "16px",
                              padding: "5px",
                            }}
                          >
                            Operational
                          </p>
                        )}
                        {value === "5" && (
                          <p
                            style={{
                              fontWeight: " bold",
                              fontSize: "16px",
                              padding: "5px",
                            }}
                          >
                            Optimized
                          </p>
                        )}
                      </li>
                    );
                  })}
                </ul>
              </div>
            </div>
            <div>
              <div>
                <h1>3. Recommendations</h1>
              </div>
              <div>
                <p style={{ fontSize: "16px", fontFamily: "Archivo" }}>
                  Enhance your cyber security controls through our
                  recommendations and by signing up for an audit.
                </p>
              </div>
              <div className="temp"></div>
              <div>
                <ul style={{ fontSize: "16px " }}>
                {lowestTwoCategories.map((category, index) => (
              <li
                style={{
                  listStyle: "circle",
                  lineHeight: "20px",
                  marginBottom: "20px",
                  paddingLeft: "10px",
                }}
                key={index}
              >
                
                <ul>
                  {randomRecommendations[category].map((line, lineIndex) => (
                    <li style={{ marginBlock: "10px" }} key={lineIndex}>
                      {line}
                    </li>
                  ))}
                </ul>
              </li>
            ))}
                </ul>
              </div>
            </div>
          </div>
          <div>
            <div>
              <h3>Need help in implementing these suggestions? </h3>
            </div>
            <div>
              <p
                style={{
                  fontSize: "16px",
                  fontFamily: "Archivo",
                  lineHeight: "20px",
                }}
              >
                Sign up for a full cyber security audit with our team where
                we’ll do a deep dive and advice on how to improve your cyber
                security.{" "}
              </p>
            </div>
            <div>
              <button className="btn-summary">
                <Link to="/apply" className="btn-summary">
                  Yes, sign-up for Audit
                </Link>
              </button>
            </div>
          </div>
          <form
            className="sum-form"
            style={{
              marginTop: "20px",
              marginBottom: "0px",
            }}
            onSubmit={handleSubmit}
          >
            <h1 style={{ marginBottom: "10px" }}>Contact Us</h1>
            <div className="form-container" style={{ marginBottom: "0px" }}>
              <div style={{ marginRight: "10px" }}>
                <label htmlFor="firstName">
                  <span style={{ color: "red" }}>*</span>First Name :
                </label>
                <input
                  style={{ marginLeft: "5px" }}
                  type="text"
                  id="firstName"
                  name="firstName"
                  required
                />
              </div>
              <div style={{ marginLeft: "50px" }}>
                <label htmlFor="lastName">
                  <span style={{ color: "red" }}>*</span>Last Name :
                </label>
                <input
                  style={{ marginLeft: "5px" }}
                  type="text"
                  id="lastName"
                  name="lastName"
                  required
                />
              </div>
            </div>
            <div className="form-container">
              <div style={{ marginRight: "10px" }}>
                <label htmlFor="email">
                  <span style={{ color: "red" }}>*</span>Email :
                </label>
                <input
                  style={{ marginLeft: "5px" }}
                  type="email"
                  id="email"
                  name="email"
                  required
                />
              </div>
              <div style={{ marginLeft: "50px" }}>
                <label htmlFor="mobile">
                  <span style={{ color: "red" }}></span>Mobile :
                </label>
                <input
                  style={{ marginLeft: "5px" }}
                  type="tel"
                  id="mobile"
                  name="mobile"
                />
              </div>
            </div>
            <button type="submit" className="btn-summary download">
              Submit
            </button>
          </form>

          <div
            style={{ marginTop: "20px", display: "flex", alignItems: "center" }}
          >
            <h3 style={{ marginRight: "10px" }}>Follow on social media:</h3>
            <span className="social">
              <a
                href="https://www.linkedin.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src="https://upload.wikimedia.org/wikipedia/commons/thumb/8/81/LinkedIn_icon.svg/768px-LinkedIn_icon.svg.png"
                  alt="LinkedIn"
                  style={{ width: "25px", height: "25px" }}
                />
              </a>
            </span>
          </div>
        </main>
      </body>
    </div>
  );
}
