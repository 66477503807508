import React, { useState, useEffect } from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import "../style/histogram.css";
import Questions from "../questions/Questions";

export default function Histogram() {
  const [answer, setAnswer] = useState([]);
  const [data, setData] = useState([
    {
      subject: "Strategy",
      Assessment_Score: 30,
      fullMark: 100,
    },
    {
      subject: "Response ",
      Assessment_Score: 30,
      fullMark: 100,
    },
    {
      subject: "Protection",
      Assessment_Score: 30,
      fullMark: 100,
    },
    {
      subject: "Identification",
      Assessment_Score: 30,
      fullMark: 100,
    },
    {
      subject: "Detection ",
      Assessment_Score: 30,
      fullMark: 100,
    },
  ]);

  useEffect(() => {
    const storedAnswer = sessionStorage.getItem("answer");
    const parsedAnswer = JSON.parse(storedAnswer);
    setAnswer(parsedAnswer);
  }, []);

  useEffect(() => {
    const ans = answer.map((val) => parseInt(val));

    const categoryScores = {};
    ans.forEach((response, index) => {
      const question = Questions[index];
      const weightage = response; 
      const category = question.category;

      if (!categoryScores[category]) {
        categoryScores[category] = 0;
      }

      categoryScores[category] += weightage;
    });

    const categoryMaxScores = {};
    for (const category in categoryScores) {
      categoryMaxScores[category] =
        5 * Questions.filter((question) => question.category === category).length;
    }

    const categoryPercentages = {};
    for (const category in categoryScores) {
      categoryPercentages[category] =
        (categoryScores[category] / categoryMaxScores[category]) * 100;
    }
    

    const categoryPercentagesLowercase = {};
  for (const category in categoryPercentages) {
    const lowercaseCategory = category.toLowerCase();
    categoryPercentagesLowercase[lowercaseCategory] = categoryPercentages[category];
  }
  
 


  const updatedData = data.map((d) => {
    const formattedSubject = d.subject.trim().toLowerCase();
    const percentage = categoryPercentages[formattedSubject] || 0;
    return { ...d, Assessment_Score: percentage };
  });
   

    setData(updatedData);
  }, [answer,]);

  return (
    <div className="histogram-container">
      <ResponsiveContainer height={300}>
        <BarChart width={500} height={300} data={data}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="subject" name="Assessment Score" />
          <YAxis domain={[0, 100]} />
          <Tooltip />
          <Legend />
          <Bar dataKey="Assessment_Score" fill="#8884d8" barSize={30} />
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
}
