const Questions = [
  {
    id: 1,
    title: "Board of directors involvement",
    question:
      "Is the board of directors effectively overseeing and promoting the cybersecurity culture within the organization by approving the cybersecurity program, determining acceptable risks, and assuming ultimate responsibility for its effectiveness?",
    category: "strategy",
    risk: "Lack of cybersecurity oversight leads to Reputation damage, Legal and regulatory consequences,  Financial loss, data compromise, operational disruption and loss of trust",
    recomendation:
      "Board of directors should be actively incolved in cybersecurity matters and is responsible to review and approve cybersecurity policies and procedures, understand cybersecurity posture and partipate in risk assessmen, reciew incident response plan and monitor, review performance  ",
  },
  {
    id: 2,
    title: "Cybersecurity Program",
    question:
      "Is the cybersecurity program aligned with your business objectives and effectively securing the organization's assets?",
    category: "strategy",
    risk: "Lack of a proper cybersecurity program increases risks to organisation and can lead to data breaches, malware infection and other incidents causing reputational and finantial loss to organisation.",
    recomendation:
      "A good cybersecurity program should incoporate inplementing strong measures such as multifactor authentication, security controls such as firewalling, intrution prevention system and regular patching, security assessments and penetration testing. Developing and maintaining a proper incident response plan.",
  },
  {
    id: 3,
    title: "Cyber Crisis Management Plan",
    question:
      "Is the cyber crisis management plan a component of the overall cybersecurity program approved by the board and is the organization fully prepared for potential crisis situations?",
    category: "strategy",
    risk: "Lack of proper cyber crisis management plan pose a significant risk to organisation and can lead to reputational damage, loss of sensitive data and disruption of business operation.",
    recomendation:
      "Organisation should develop and implement robest cyber crysis plan by identifying critical assets and key stakehonders and establishing their roles and responsibilities, developing a plan for incident response and recovery, defining channels for communicating with key stake holders, testing it by conducting regular drills.",
  },
  {
    id: 4,
    title: "Risk Assessment",
    question:
      "Does your organization conducts periodic enterprise-wide risk assessments to proactively identify unknown cybersecurity risks?",
    category: "strategy",
    risk: "",
    recomendation:
      "Conduct regular, proactive enterprise-wide risk assessments to identify and mitigate potential unknown cybersecurity risks. This can help meet regulatory requirements and significantly reduce the risk of cyber incidents.",
  },
  {
    id: 5,
    title: "Roles and Responsibility ",
    question:
      "Is every level of the organization, including senior management, standard users, privileged users, cybersecurity personnel, physical security personnel, and third parties, aware of their respective roles and responsibilities?",
    category: "identification",
    risk: "Undefined roles and responsibilities leads to lack of cybersecuroty oversights, inefficient cybersecurity program, misallignment with organisation goals",
    recomendation:
      "Organisation should establish clearly defined cybersecurity roles and responsibilities making all employees aware of their roles through a governance lead. ",
  },
  {
    id: 6,
    title: "Idenitify vendors, contractors and thirdparty",
    question:
      "Is there a documented and comprehensive list of vendors who have access to the organization's data and processes?",
    category: "identification",
    risk: "Lack of proper identification of vendors, contractors and thirdparties can lead to finantial, reputational and legal risk to organisation due to unauthorised access and compromose",
    recomendation:
      "Organizations should identify the vendors, contractors and other third-parties they are engaged with.",
  },
  {
    id: 7,
    title: "Identify workforce",
    question:
      "Is there a regularly maintained and updated list of employees, with periodic reviews to confirm that resigned employees no longer have active access to the organization's data?",
    category: "identification",
    risk: "Lack of an identified workforce poses a significant risk to an organization's cybersecurity posture and highlights the importance of implementing proper identification and access controls to protect sensitive information and systems.",
    recomendation:
      "Organizations should accurately identify their workforce to manage the risks associated with them. ",
  },
  {
    id: 8,
    title: "Identify Data",
    question:
      "Has the critical and valuable data been categorized and given proper protection?",
    category: "identification",
    risk: "Lack of identified data can lead to increased risks of data breaches, unauthorized access, and compliance violations, as it becomes difficult to determine which data is sensitive and should be protected.",
    recomendation:
      "Organizations should properly identify and classify their data to ensure that appropriate protection measures are applied.",
  },
  {
    id: 9,
    title: "Identify hardware and software",
    question:
      "Is every software component, key technology, and hardware device identified, classified, and frequently verified for accuracy?",
    category: "identification",
    risk: "The risk related to identifying hardware and software is the potential for security vulnerabilities to go unnoticed, as it becomes difficult to manage and secure assets that are not identified.",
    recomendation:
      "Organizations should properly identify and classify their hardware and software used acorss the entriprise to ensure appropriate protection measures are applied.",
  },
  {
    id: 10,
    title: "Data Protection",
    question:
      "Has the organization put in place sufficient controls to protect data at rest, in transit, maintain data integrity, and prevent data leakage?",
    category: "protection",
    risk: "Lack of proper data protection measures pose significant risk to organisation. It could lead to sensitive data exposure to unauthorised access and misuse causing finantial and reputational damage to organization ",
    recomendation:
      "Organisations should implement robest security controls like data encryption, access control, integrity checks, data backup, regular patching etc. to safeguard data at rest and in transit." ,
  },
  {
    id: 11,
    title: "Communication and control networks",
    question:
      "Has the organization implemented controls to secure communication and control networks, such as operational technology, from potential cyber attacks?",
    category: "protection",
    risk: "Protecting control and communication network is critical to sustain operation of business and lack of proper countermeasures to  protect against attacks leads to business disruption",
    recomendation:
      "Controls and communication network should be protected with robust countermeasures to prevent disruption. Acurately identifying network components, firewalling, segmenting,  intrution detection, prevention system  and timely patching should be implimented.",
  },
  {
    id: 12,
    title: "Security baseline",
    question:
      "Are the baseline configurations of devices, servers, and applications established inline with industry best practices.",
    category: "protection",
    risk: "A security baseline is a set of standards and guidelines that organizations use to ensure the security of their systems, networks, and data. It is a baseline level of security that an organization should maintain in order to protect its assets and meet regulatory requirements. Without a properly documented security baseline, it would be difficult for an organisation to maintain standards and guidelines inorger to protect organisation assets like networks, systems, application and data",
    recomendation:
      "Organisation should have a security baseline consisting of standard guidlines to protect its assets by acurately identifying assets, developing security policies, procedures based on regulatory requirements. It is also recomended to Implement security controls, continuously assess, monitor, update and train employees. ",
  },
  {
    id: 13,
    title: "Secure System development lifecycle ",
    question:
      "Has the organization taken security and privacy by design in all stages of System Development Life Cycle by adopting Shift left approach",
    category: "protection",
    risk: "Security and privacy requirements, if not considered in the design phase will cause difficulty in managing it in the later stages of the system development and increases the overall cost and chance of system compromose",
    recomendation:
      "Organisation should incoporate security and privacy policy in the early phases of systems development. A proper risk assessment shall be carried out involving expert to identify vulnerabilities during all key product development stages.",
  },
  {
    id: 14,
    title: "Change management",
    question:
      "Does the organization have full control over all system and device configurations to prevent unauthorized changes?",
    category: "protection",
    risk: "Improper change management in a organisation could lead to configuration errors in devices and systems, unauthorised changes affecting and change failures leading to business disruption, integrity and confidentiality compromise.",
    recomendation:
      "Organisation should develop and implement a robust change management policy and make it operational by conducting regular risk assessment and appropriate testing. ",
  },
  {
    id: 15,
    title: "Vulnerability management plan ",
    question:
      "Has a vulnerability management plan been developed and implemented effectively? And are there established regulatory requirements for reporting vulnerabilities, such as reporting to a national cybersecurity agency within a specified time frame?",
    category: "protection",
    risk: "Vulnerability management is an important activity of risk management. Lack of proper vulnerability management could lead to exposed vulnerability to be exploited and compromised to affect organisation assets.",
    recomendation:
      "The organization must implement a well-executed vulnerability management plan to guarantee the security of its systems and networks. This plan should involve regularly scanning for vulnerabilities and prioritizing any identified risks based on their level of severity. Timely remediation of vulnerabilities is crucial, and the organization should have established procedures in place to address new risks and comply with regulatory reporting requirements",
  },
  {
    id: 16,
    title: "Incident response plan",
    question:
      "Is there an incident response plan in place, with defined roles and responsibilities that have been communicated and periodically tested?",
    category: "protection",
    risk: "Without a plan, it can lead to confusion and chaos, making it difficult to minimize damage and recover quickly.",
    recomendation:
      "An incident response plan is a crucial element in an organization's cyber security framework. It's important to develop, test and update the plan regularly, and to ensure that all employees are aware of their roles and responsibilities during an incident.",
  },
  {
    id: 17,
    title: "Audit logs",
    question:
      "Is effective audit logging implemented and periodically reviewed in accordance with the cybersecurity policy to aid in tracing accountability?",
    category: "protection",
    risk: "A lack of audit logs can be a significant risk for an organization. Audit logs are used to track and record user activity within a system or network, including login attempts, file access, and other types of activity. Without these logs, it can be difficult or impossible to detect and investigate security incidents, such as unauthorized access or data breaches.",
    recomendation:
      "1- Enable audit logging on all systems and networks to monitor user activity. 2- Conduct regular reviews and analysis of audit logs to identify unusual or suspicious behavior.  3- Establish an alert system for security staff or incident response teams in case of unusual activity. 4- Retain audit logs for compliance and forensic purposes for a 5- specified period of time. 5-Ensure secure storage of logs with limited access to authorized personnel only. 6- Verify the integrity of logs on a regular basis. 7- Test log collection and retention processes regularly to identify and address potential issues.",
  },
  {
    id: 18,
    title: "Removable media",
    question:
      "Are there adequate protection controls in place to defend against cyber attacks via removable media?",
    category: "protection",
    risk: "Not having proper data backup in place to recover recent data in the event of a disaster can be a significant risk for an organization. Disasters such as fires, floods, or cyber-attacks can cause data loss, and without proper backup, it can be difficult or impossible to recover that data.",
    recomendation:
      "1. Implement endpoint security solutions for detection and blocking of malicious software on removable media. 2. Limit or disable unnecessary use of removable media on specific systems or networks. 3. Encrypt all removable media to secure sensitive data. 4. Scan and clean all removable media before usage on systems or networks. 5.Train employees on safe usage of removable media and related risks. 6. Monitor network activity for any suspicious activity involving removable media.",
  },
  {
    id: 19,
    title: "Backup",
    question:
      "Does the organization have proper data backups in place to recover the most recent data in the event of a disaster, such as a ransomware attack?",
    category: "protection",
    risk: "Not having proper data backup in place to recover recent data in the event of a disaster can be a significant risk for an organization. Disasters such as fires, floods, or cyber-attacks can cause data loss, and without proper backup, it can be difficult or impossible to recover that data.",
    recomendation:
      "Cybersecurity data backup plan: 1. Regular backups of essential data is crucial. 2. Off-site/cloud storage to guard against on-site disruptions. 3. Multiple backup copies for availability during disasters. 4. Regular backup testing to confirm completeness and restoration viability. 5. Disaster recovery plan in place for rapid data recovery and business continuity. 6. Monitoring backup retention periods and archiving outdated data. 7. Continuously monitoring backup processes for proper operation.",
  },
  {
    id: 20,
    title: "Asset destruction",
    question:
      "Is there a defined data destruction policy in place for the organization?",
    category: "protection",
    risk: "The cybersecurity risk related to the lack of asset destruction planning is the potential for data loss, system downtime, and inability to recover critical data or systems after an attack or disaster. Proper planning, such as regular backups and offsite storage, can help mitigate this risk.",
    recomendation:
      "Organizations should implement an asset destruction policy and ensure that assets are correctly disposed to manage the risks associated with them.",
  },
  {
    id: 21,
    title: "Cyber resilience ",
    question:
      "Has the organization implemented appropriate fail-safe mechanisms to protect assets and maintain business continuity, in accordance with cyber resilience requirements? [While a cybersecurity strategy can help prevent a data breach or reduce the risk of malicious activity, a cyber resilience strategy specifically helps mitigate the impacts of these attack]",
    category: "protection",
    risk: "Not having appropriate failsafe mechanisms in place can be a significant risk for an organization, as it can leave assets and business operations vulnerable to disruptions. Failsafe mechanisms are designed to protect against failures or disruptions and to ensure that business continuity is maintained in the event of an incident.",
    recomendation:
      "Regularly monitor data flow to detect organizations any abnormal or suspicious activity such as large data transfers to unfamiliar locations. Implementing DLP solutions can prevent sensitive data exfiltration. Organizations should also utilize threat intelligence feeds to stay updated on the latest threats and enhance their monitoring capabilities.",
  },
  {
    id: 22,
    title: "Intelligence and Operational dataflow",
    question:
      "Is the network operations and user data flow optimized based on the latest intelligence data for effective detection of malicious events?",
    category: "detection",
    risk: "Sensitive data may be exfiltrated by malicious actors without proper detection of teh events.",
    recomendation:
      "Organizations should implement strategies to meet regulatory compliance and avoid potential penalties while preserving their reputation. This can include regularly monitoring and reporting compliance-related events.",
  },
  {
    id: 23,
    title: "Incident Impact",
    question:
      "Are there procedures in place for categorizing different malicious events to take action based on varying impact levels?",
    category: "detection",
    risk: "",
    recomendation:
      "Organizations should regularly assess and monitor their cybersecurity posture to identify and mitigate potential risks.",
  },
  {
    id: 24,
    title: "Compliance",
    question:
      "Is the detection of cyber incidents compliant with regulatory requirements?",
    category: "detection",
    risk: "The cybersecurity risk related to the lack of compliance with regulatory requirements for detecting cyber incidents is the potential for legal and financial penalties. Organizations that fail to comply with regulatory requirements may face consequences such as fines, legal action, and damage to their reputation.",
    recomendation:
      "Organizations should regularly scan their networks, systems, and applications for vulnerabilities.",
  },
  {
    id: 25,
    title: "Continuous monitoring",
    question:
      "Is continuous monitoring in place to detect all possible cybersecurity events across the following areas: Network activity Physical environment Personnel activity Malicious code External service providers Suspicious activity monitoring?",
    category: "detection",
    risk: "",
    recomendation:
      "Organizations should implement a comprehensive vulnerability management program to detect and remediate potential vulnerabilities in their networks, systems, and applications.",
  },
  {
    id: 26,
    title: "Vulnerability scanning",
    question:
      "Are vulnerability scans conducted periodically to identify vulnerabilities in a timely manner?",
    category: "detection",
    risk: "",
    recomendation:
      "Organizations should have a well-defined media and public relations strategy to effectively communicate and maintain their reputation during a cyber incident.",
  },
  {
    id: 27,
    title: "Response plan",
    question:
      "Is there a clearly defined cyber response plan in place for managing major security incidents?",
    category: "response",
    risk: "",
    recomendation:
      "Having a well-defined incident response plan in place is crucial for effectively responding to a cyber incident.",
  },
  {
    id: 28,
    title: "Investigation and Forensic analysis",
    question:
      "Does your organization have the in-house capability to conduct forensic investigations or have contracts with forensic investigation experts from the industry?",
    category: "response",
    risk: "",
    recomendation:
      "After a cyber incident, organizations should conduct a thorough investigation to determine the cause and scope of the incident, as well as identify any potential vulnerabilities that may have been exploited.",
  },
  {
    id: 29,
    title: "New vulnerability management",
    question:
      "Are procedures in place for receiving, analyzing, and responding to newly discovered vulnerabilities from both internal and external sources?",
    category: "response",
    risk: "",
    recomendation:
      "Organizations should have a comprehensive vulnerability management program in place to identify and address potential vulnerabilities in their networks, systems, and applications",
  },
  {
    id: 30,
    title: "Media, public relations and reputation",
    question:
      "Is there a comprehensive internal and external communication strategy in place to handle public relations following a significant cybersecurity breach, aimed at reducing the damage to the company's reputation?",
    category: "response",
    risk: "",
    recomendation:
      "Organizations should have a well-defined media and public relations plan in place to manage the communication and reputation in case of a cyber incident.",
  },
];
export default Questions;
