import "./App.css";
import React, { lazy, Suspense } from "react";
import Home from "./components/Home";

import { Route, Routes } from "react-router-dom";
import ApplyNow from "./components/ApplyNow";
import Summary from "./components/Summary";
import Second from "./components/SecondPage";
import First from "./components/First";

// const Home = lazy(() => import("./components/Home"));
// const ApplyNow = lazy(() => import("./components/ApplyNow"));
// const Summary = lazy(() => import("./components/Summary"));
// const Second = lazy(() => import("./components/SecondPage"));
// const First = lazy(() => import("./components/First"));



function App() {
  return (
    <>
    <Suspense fallback={<h1>loading...</h1>}>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/apply" element={<ApplyNow />} />
        <Route path="/second" element={<Second />} />

        <Route path="/question" element={<First />} />
        <Route path="/sum" element={<Summary />} />
      </Routes>
      </Suspense>
    </>
  );
}
export default App;
