import React, { useState, useEffect } from "react";
import Questions from "../questions/Questions";
import "../style/QuestionPage.css";
import "../style/Slider.css";

function First() {
  const [current, setCurrent] = useState(0);
  const [check, setCheck] = useState("");
  const [answer, setAnswer] = useState([]);
  const [next, setSubmit] = useState("Next");

  useEffect(() => {
    setCheck(answer[current] || "");
  }, [current, answer]);

  const handleClick = () => {
    if (current < Questions.length) {
      if (check !== "") {
        answer[current] = check;
        
        setCurrent(current + 1);
        setCheck("");
      } else {
        alert("Please choose an option");
      }
    }

    if (current === Questions.length - 1) {
      window.location.pathname = "/sum";
      sessionStorage.setItem("answer", JSON.stringify(answer));
    }
  };

  const handleClicked = () => {
    window.location.pathname = "/";
  };

  const handlePrevious = () => {
    setSubmit("Next");

    if (current > 0) {

      if (check !== answer[current]) {
        answer[current] = check;
        
      }

      setCurrent(current - 1);
      setCheck(answer[current - 1] || "");
    } else {
      alert("You can't go back again");
    }
  };

  return (
    <div>
      <top className="top">
        <ma className="total">
          <img
            id="x"
            className="x"
            onClick={handleClicked}
            src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAADbSURBVHgB7ZQxDsIwDEWdVNwBiSVsLEhcoTcpJ4Ob9ApILGxkQeoRkFAJcclQLDsxjLR/aevG/1XObwBm/b0MV1xt91e89o9Qd5ejzxksN42rFqbF+9v5sKbvLdcU3pehEQ2gbO6CsIYFPKtQxwafgxBzjz2cFzuiwWDXONubNi5w8dGPx8WZdyd+lCJAgmBda14EcJBUVpmrAAwEtOYoCxrdP7/EpJpG5RGRDU1NDsjG/wSQoiil6ytALoq5CKsAmpxrIewma3KONfrHc17iWaSJ4hginUWzJqAXqxq3EgwwyXMAAAAASUVORK5CYII="
            alt="Close"
          />
          <div className="progress">
            <progress
              id="progressBar"
              max="100"
              value={(Questions[current].id * 100) / 30}
            ></progress>
          </div>

          <div style={{ color: "#1B2D61", }}>Question {Questions[current].id}</div>

          <div className="qn-title" style={{ color: "#1B2D61" }}>
            <h3>{Questions[current].title}</h3>
          </div>

          <div className="qn-details" style={{ color: "#1B2D61", }}>
            <p>{Questions[current].question}</p>
          </div>

          <div className="options">
            <div id="form-wrapper">
              <form className="slider-form" method="GET">
                <div id="slider">
                  <input
                    type="radio"
                    name="option"
                    id="1"
                    onChange={(e) => setCheck(e.target.value)}
                    checked={check === "1"}
                    value="1"
                    required
                    data-testId="question-1-option-1"
                  />
                  <label htmlFor="1" respective-value="Doesn't exist"></label>

                  <input
                    type="radio"
                    name="option"
                    id="2"
                    value="2"
                    onChange={(e) => setCheck(e.target.value)}
                    checked={check === "2"}
                    required
                  />
                  <label htmlFor="2" respective-value="In development"></label>

                  <input
                    type="radio"
                    name="option"
                    id="3"
                    value="3"
                    onChange={(e) => setCheck(e.target.value)}
                    checked={check === "3"}
                    required
                  />
                  <label htmlFor="3" respective-value="Implemented"></label>

                  <input
                    type="radio"
                    name="option"
                    id="4"
                    value="4"
                    onChange={(e) => setCheck(e.target.value)}
                    checked={check === "4"}
                    required
                  />
                  <label htmlFor="4" respective-value="Operational"></label>

                  <input
                    type="radio"
                    name="option"
                    id="5"
                    value="5"
                    onChange={(e) => setCheck(e.target.value)}
                    checked={check === "5"}
                    required
                  />
                  <label htmlFor="5" respective-value="Optimized"></label>
                  
                </div>
          
              </form>
            </div>
          </div>    
            <div className="prev-next" >
          <button className="prev" onClick={handlePrevious}>
            Previous
          </button>
          <button className="next" onClick={handleClick}>
            {current === Questions.length - 1 ? "Submit" : "Next"}
          </button>
        </div>
        </ma>

        
      </top>
    </div>
  );
}

export default First;
