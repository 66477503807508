import React from "react";
import { useEffect, useState } from "react";
import {
  Radar,
  RadarChart,
  PolarGrid,
  PolarAngleAxis,
  PolarRadiusAxis,
} from "recharts";
import { ResponsiveContainer } from "recharts";
import Questions from "../questions/Questions";
// import "../style/pentagon.css";

export default function Pentagon() {
  const [answer, setAnswer] = useState([]);
  const [data, setData] = useState([
    {
      subject: "Strategy",
      A: 30,
      fullMark: 100,
    },
    {
      subject: "Response",
      A: 30,
      fullMark: 100,
    },

    {
      subject: "Protection",
      A: 30,
      fullMark: 100,
    },

    {
      subject: "Identification",
      A: 30,
      fullMark: 100,
    },

    {
      subject: "Detection",
      A: 30,
      fullMark: 100,
    },
  ]);

  useEffect(() => {
    const storedAnswer = sessionStorage.getItem("answer");
    const parsedAnswer = JSON.parse(storedAnswer);
    setAnswer(parsedAnswer);
  }, []);

  useEffect(() => {
    const ans = answer.map((val) => parseInt(val));

    const categoryScores = {};
    ans.forEach((response, index) => {
      const question = Questions[index];
      const weightage = response; 
      const category = question.category;

      if (!categoryScores[category]) {
        categoryScores[category] = 0;
      }

      categoryScores[category] += weightage;
    });

    const categoryMaxScores = {};
    for (const category in categoryScores) {
      categoryMaxScores[category] =
        5 * Questions.filter((question) => question.category === category).length;
    }

    const categoryPercentages = {};
    for (const category in categoryScores) {
      categoryPercentages[category] =
        (categoryScores[category] / categoryMaxScores[category]) * 100;
    }
    

    const categoryPercentagesLowercase = {};
  for (const category in categoryPercentages) {
    const lowercaseCategory = category.toLowerCase();
    categoryPercentagesLowercase[lowercaseCategory] = categoryPercentages[category];
  }
  



  const updatedData = data.map((d) => {
    const formattedSubject = d.subject.trim().toLowerCase();
    const percentage = categoryPercentages[formattedSubject] || 0;
    return { ...d, A: percentage };
  });
 

    setData(updatedData);
  }, [answer]);



  return (
    <div
      style={{
        backgroundColor: "white",
      }}
    >
      <ResponsiveContainer height={300} width="80%">
        <RadarChart height={500} width={500} data={data}>
          <PolarGrid style={{ stroke: "#1B2D61", opacity: 0.65 }} />

          <PolarAngleAxis dataKey="subject" style={{ fontSize: "14px" }} />
          <PolarRadiusAxis domain={[0, 100]} stroke="rgba(27, 45, 97, 0.65)" />

          <Radar dataKey="A" stroke="pink" fill="pink" fillOpacity={0.5} />
        </RadarChart>
      </ResponsiveContainer>
    </div>
  );
}
