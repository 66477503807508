import React, { useState } from "react";

import "../style/Download.css";
import Pentagon from "./Pentagon";
import Questions from "../questions/Questions";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import { useEffect } from "react";
import newImage from "../images/report.jpg";
import Histogram from "./Histogram";
import footer from '../images/footer.jpg'



function getRandomRecommendations(recommendationsString, count) {
  const recommendationsArray = recommendationsString.split('\n');
  const randomRecommendations = [];

  while (randomRecommendations.length < count && recommendationsArray.length > 0) {
    const randomIndex = Math.floor(Math.random() * recommendationsArray.length);
    const randomRecommendation = recommendationsArray.splice(randomIndex, 1)[0];
    randomRecommendations.push(randomRecommendation);
  }

  return randomRecommendations;
}

export default function Download({openDownload}) {

  const [isGenerating, setIsGenerating] = useState(false);
  const [isProgress, setIsProgress] = useState(0);
  const [downloadUrl, setDownloadUrl] = useState(null);
 


  function pdfConvert() {
    setIsGenerating(true);
    setIsProgress(0);

    const doc = new jsPDF({
      orientation: "portrait",
      unit: "mm",
      format: "a4",
      marginLeft: 20,
      marginRight: 20,
      marginTop: 10,
      marginBottom: 25,
    });

    const element = document.getElementById("to-download");
    const title = "teqnia Information Security Assessment tool";

    let progressInterval; 
    progressInterval = setInterval(() => {
      
      setIsProgress((prevProgress) => Math.min(prevProgress + 10, 100));
    }, 500);
  
    html2canvas(element, {
      onprogress: (progressData) => {
        console.log(progressData);
        const currentProgress = Math.round((progressData.loaded / progressData.total) * 100);
        setIsProgress(currentProgress);
      },
    }).then((canvas) => {
      const pageWidth = doc.internal.pageSize.getWidth();
      const pageHeight = doc.internal.pageSize.getHeight()-15;
      const scaleFactor = pageWidth / canvas.width;
      const imgWidth = pageWidth - 25;
      const imgHeight = canvas.height * scaleFactor - 2;
      const totalPages = Math.ceil(imgHeight / pageHeight);
      const newImageWidth = 210;
      const newImageHeight = 310;
    
      let position = 5;

      progressInterval = setInterval(() => {
        setIsProgress((position / imgHeight) * 100);
      }, 100);

      doc.addImage(
        newImage,
        "PNG",
        (pageWidth - newImageWidth) / 2,
        (pageHeight - newImageHeight) / 2 + 10,
        newImageWidth,
        newImageHeight
      );

      doc.addPage();
      for (let i = 1; i <= totalPages; i++) {
        doc.setDrawColor(0);
        doc.setFillColor(255, 255, 255);
        doc.rect(0, 0, pageWidth, pageHeight, "F");

        doc.addImage(
          canvas.toDataURL("image/png"),
          "PNG",
          13,
          position + 10,
          imgWidth,
          imgHeight - 55,
          undefined,
          "FAST"
        );

        doc.setFillColor(255, 255, 255);
        doc.rect(10, pageHeight - 9, pageWidth - 20, pageHeight - 60, "F");

        doc.setFillColor(255, 255, 255);
        doc.rect(10, -60, pageWidth - 20, 68.7, "F");

        addFooter(doc, i + 1, totalPages + 1);
        position -= pageHeight - 20;

        if (i === totalPages) {
          break;
        }
        doc.addPage();
      }

      doc.save(title);
      
      clearInterval(progressInterval); 

      const pdfBlob = doc.output("blob");

  
  const url = URL.createObjectURL(pdfBlob);
  setDownloadUrl(url);

    setTimeout(() => {
     setIsProgress(100);
      setIsGenerating(false);
    }, 500);;
    });
  }
  // function addHeader(doc, title) {
  //   doc.setFontSize(10);
  //   doc.setTextColor(0, 0, 0);
  //   doc.text(title, 20, 15);
  // }

  function addFooter(doc, currentPage, totalPages) {
    doc.setFontSize(10);
    doc.setTextColor(128);
 
  doc.addImage(
    footer,
    doc.internal.pageSize.getWidth() - 220,
      doc.internal.pageSize.getHeight() - 20,
    220,
    15,
    undefined,
    "FAST",
  );

  }
  
  const [answer, setAnswer] = useState([]);
  useEffect(() => {
    const storedAnswer = sessionStorage.getItem("answer");
    const parsedAnswer = JSON.parse(storedAnswer);
    setAnswer(parsedAnswer);
  }, []);
  let ans = answer.map((val) => parseInt(val));


  const categoryScores = {};
  ans.forEach((response, index) => {
    const question = Questions[index];
    const weightage = response; 
    const category = question.category;
  
    if (!categoryScores[category]) {
      categoryScores[category] = 0;
    }
  
    categoryScores[category] += weightage;
  });
 
  const categoryMaxScores = {};
  for (const category in categoryScores) {
    categoryMaxScores[category] = 5 * Questions.filter(question => question.category === category).length;
  }
  const categoryPercentages = {};
  for (const category in categoryScores) {
    categoryPercentages[category] = (categoryScores[category] / categoryMaxScores[category]) * 100;
  }


  const lowestTwoCategories = Object.keys(categoryPercentages)
  .sort((a, b) => categoryPercentages[a] - categoryPercentages[b])
  .slice(0, 2);

  const recommendations = {};
  for (const category of lowestTwoCategories) {
    const question = Questions.find(q => q.category === category);
    recommendations[category] = question.recomendation; 
  }

  const randomRecommendations = {};

  for (const category of lowestTwoCategories) {
    const categoryQuestions = Questions.filter(question => question.category === category);
    const allCategoryRecommendations = categoryQuestions.map(question => question.recomendation);
    const randomRecs = getRandomRecommendations(allCategoryRecommendations.join('\n'), 2);
    randomRecommendations[category] = randomRecs;
  }
// console.log(isProgress)
  return (
    <div>
      {/* Modal */}
      <div className="modal-click">
      <div className="modal-shut">
      <div className="cancel-icon">
          <svg className="icon-x" xmlns="http://www.w3.org/2000/svg" onClick={openDownload} viewBox="0 0 24 24">
            <path d="M12.0007 10.5865L16.9504 5.63672L18.3646 7.05093L13.4149 12.0007L18.3646 16.9504L16.9504 18.3646L12.0007 13.4149L7.05093 18.3646L5.63672 16.9504L10.5865 12.0007L5.63672 7.05093L7.05093 5.63672L12.0007 10.5865Z"></path>
          </svg>
        </div>
        {isGenerating && (
            <div className="download-progress">
              <p>Generating PDF...</p>
              <div className="custom-progress">
                <div className="progress-bar" style={{ width: `${isProgress}%` }}></div>
              </div>
            </div>
          )}
          
          {!isGenerating && downloadUrl && (
            <a className="modal-button" style={{ width: "150px", height: "20px" }} href={downloadUrl} download="summary.pdf">
              Download
            </a>
          )}
         
          {!isGenerating && !downloadUrl && (
            <button
              className="modal-button"
              style={{ width: "150px", height: "40px" }}
              onClick={pdfConvert}
              disabled={isGenerating}
            >
              Download
            </button>
          )}
       
        </div>
        
      </div>
      
       
      <body>
        <main className="body-sum" style={{ background: "white" }}>
          

          <div id="to-download" style={{ overflow: "auto", width: "750px" }}>
            <div>
              <h1
                style={{
                  padding: "10px",
                  fontSize: "44px",
                  textAlign: "center",
                }}
              >
                Summary{" "}
              </h1>

              <div>
                <h1>1. Visualization</h1>
              </div>
              <div>
                <p style={{ fontSize: "16px" }}>
                  Below graph depicts the summary of the profile based on the
                  assessment
                </p>
              </div>

              <div className="centered-container polygons">
                <div style={{ marginLeft: "40px" }}>
                  <Pentagon />
                </div>
                <div>
                  <Histogram />
                </div>
              </div>

              
            </div>
            <div className="Q-and-A">
              <div>
                <h1>2. Questions and Responses</h1>
              </div>

              <div>
                <ul>
                  {answer.map((value, index) => {
                    return (
                      <li
                        style={{
                          listStyle: "circle",
                          fontSize: "16px",
                          lineHeight: "20px",
                          marginBottom: "10px",
                        }}
                        key={index}
                      >
                        {index + 1}. {Questions[index].question}
                        {value === "1" && (
                          <p
                            style={{
                              fontWeight: " bold",
                              fontSize: "16px",
                              padding: "5px",
                            }}
                          >
                            Does Not Exist
                          </p>
                        )}
                        {value === "2" && (
                          <p
                            style={{
                              fontWeight: "bold",
                              fontSize: "16px",
                              padding: "5px",
                            }}
                          >
                            In Development
                          </p>
                        )}
                        {value === "3" && (
                          <p
                            style={{
                              fontWeight: "bold",
                              fontSize: "16px",
                              padding: "5px",
                            }}
                          >
                            Implemented
                          </p>
                        )}
                        {value === "4" && (
                          <p
                            style={{
                              fontWeight: " bold",
                              fontSize: "16px",
                              padding: "5px",
                            }}
                          >
                            Operational
                          </p>
                        )}
                        {value === "5" && (
                          <p
                            style={{
                              fontWeight: " bold",
                              fontSize: "16px",
                              padding: "5px",
                            }}
                          >
                            Optimized
                          </p>
                        )}
                      </li>
                    );
                  })}
                </ul>
              </div>
            </div>
            <div>
              <div>
                <h1>3. Recommendations</h1>
              </div>
              <div>
                <p style={{ fontSize: "16px", fontFamily: "Archivo" }}>
                  Enhance your cyber security controls through our
                  recommendations and by signing up for an audit.
                </p>
              </div>
              <div className="temp"></div>
              <div>
                <ul style={{ fontSize: "16px " }}>
                {lowestTwoCategories.map((category, index) => (
              <li
                style={{
                  listStyle: "circle",
                  lineHeight: "20px",
                  marginBottom: "20px",
                  paddingLeft: "10px",
                }}
                key={index}
              >
                
                <ul>
                  {randomRecommendations[category].map((line, lineIndex) => (
                    <li style={{ marginBlock: "10px" }} key={lineIndex}>
                      {line}
                    </li>
                  ))}
                </ul>
              </li>
            ))}
                </ul>
              </div>
            </div>
          </div>
        </main>
      </body>
      
    </div>
  );
}
