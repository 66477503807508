import React, { useState } from "react";
import "../style/ApplyNow.css";
import country from "./countrylist";





export default function ApplyNow() {
  const [formData, setFormData] = useState({});
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const countryList = country;
  const [allFieldsFilled, setAllFieldsFilled] = useState(false);
  const [incompleteFieldsMessage, setIncompleteFieldsMessage] = useState("");
  const [selectedCountryCode, setSelectedCountryCode] = useState("+91");

  


 
 
  const handleInputChange = (event) => {
    const target = event.target;
    const name = target.name;
    let value = target.value;

    

    if (name === "country") {
      const selectedCountry = countryList.find(
        (country) => country.name === value
      );

      if (selectedCountry) {
        setSelectedCountryCode(selectedCountry.dial_code);
      }
    }

    setFormData({
      ...formData,
      [name]: value,
    });



  };
  const handleInputBlur = () => {
    const requiredFields = ["name", "Email", "country", "expertise"];
    const areAllFieldsFilled = requiredFields.every((field) => !!formData[field]);
    setAllFieldsFilled(areAllFieldsFilled);

    if (incompleteFieldsMessage) {
      setIncompleteFieldsMessage("");
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const requiredFields = ["name", "Email", "country", "expertise"];
    const incompleteFields = requiredFields.filter((field) => !formData[field]);
  
    if (incompleteFields.length === 0) {
      setShowSuccessMessage(true);
      
  
      setTimeout(() => {
        setShowSuccessMessage(false);
        window.location.pathname = "/"; 
      }, 1500);
    } else {
      setIncompleteFieldsMessage("Please fill in all required fields.");
    }
    console.log(formData)
  };

  const handleClick = (e) => {
    e.preventDefault();
    window.location.pathname = "/";
  };

  return (
    <div>
      <body>
        <main>
          <img
            id="x"
            className="x"
            alt="i"
            onClick={handleClick}
            src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAADbSURBVHgB7ZQxDsIwDEWdVNwBiSVsLEhcoTcpJ4Ob9ApILGxkQeoRkFAJcclQLDsxjLR/aevG/1XObwBm/b0MV1xt91e89o9Qd5ejzxksN42rFqbF+9v5sKbvLdcU3pehEQ2gbO6CsIYFPKtQxwafgxBzjz2cFzuiwWDXONubNi5w8dGPx8WZdyd+lCJAgmBda14EcJBUVpmrAAwEtOYoCxrdP7/EpJpG5RGRDU1NDsjG/wSQoiil6ytALoq5CKsAmpxrIewma3KONfrHc17iWaSJ4hginUWzJqAXqxq3EgwwyXMAAAAASUVORK5CYII="
          />
          <div data-testid="apply-heading">
            <h1>Apply Now</h1>
          </div>
          <form>
            <div className="form-input">
              <label htmlFor="inp_name" className="label">
                Full name*
              </label>
              <input
                id="inp_name"
                className="input"
                type="text"
                placeholder="Enter full name"
                onChange={handleInputChange}
                onBlur={handleInputBlur}
                name="name"
                required
              />
            </div>

            <div className="form-input">
              <label htmlFor="inp_email" className="label">
                Email*
              </label>
              <input
                id="inp_email"
                className="input"
                type="email"
                placeholder="Enter email address"
                onChange={handleInputChange}
                onBlur={handleInputBlur}
                name="Email"
                required
              />
            </div>

            <label htmlFor="country-select" className="label">
              Contry of Residence*
            </label>
            
            <select
            id="country-select"
            name="country"
            type="select-multiple"
            onChange={handleInputChange}
            style={{
              position: "relative",
              top: "0px",
            }}
            required
          >
            <option value="">--Select one---</option>
            {countryList.map((country) => (
              <option
                value={country.name}
                key={country.code}
              >
                {country.name}
              </option>
            ))}
          </select>

          <div>
            <label id="phonenumber" className="phonenumber label">
              Phone number (optional)
            </label>
            <div></div>
            <div className="phone-input-container">
              <input
                value={selectedCountryCode}
                className="code-input"
                readOnly
              />
              <input
                id="inp_phone"
                type="tel"
                inputmode="numeric" 
               
                className="rectangle number-input"
                placeholder="Mobile Number"
                onChange={handleInputChange}
                name="Mobile"
                maxLength={15}
              />
            </div>
          </div>

            <div className="area-expertise">
              <span className="areas">
                <h4>Area of expertise *</h4>
              </span>
              <div className="input-select">
              <input type="text" placeholder="Type your answer"
              id="inp_expertise"
              className="input"
              onChange={handleInputChange}
              onBlur={handleInputBlur}
              name="expertise"
              required
              />
      
              </div>

     
            </div>
            {incompleteFieldsMessage && (
              <div className="error-message">
                {incompleteFieldsMessage}
              </div>
            )}
            <div className="apply" onClick={handleSubmit}>
            <button className="app-btn" >
              Apply Now
            </button>
          </div>
          </form>
          
          {allFieldsFilled && showSuccessMessage && (
            <div className="popup" onClick={handleClick}>
              <p>Form submitted successfully!</p>
              
            </div>
          )}
        </main>
      </body>
    </div>
  );
}
