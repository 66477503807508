import React, { useState } from "react";
import vector from "../images/Vector.png";
import "../css/home.css";

import { Link } from "react-router-dom";

function Home() {
  const [showName, setShowName] = useState(false);

  const handleHover = () => {
    setShowName(true);
  };

  const handleMouseLeave = () => {
    setShowName(false);
  };

  return (
    <div className="app-home">  
      <div className="app-card">
        <div className="header">Welcome to Teqniaai</div>
        <div className="disc-home" style={{ textAlign: "center" }}>
          Are you a business lost in Cyber Security challenges? <br />
          Our assessment tool will help you get started. </div>
        <div
          className="sub-head-home"
          onMouseEnter={handleHover}
          onMouseLeave={handleMouseLeave}
          data-testid="hover"
        >
          {!showName && "What is Teqniaai?"}
          {showName && (
            <span>
              We are a Cyber Security solutions company based out of Bangalore,<br />
              India enabling businesses to operate in a Cyber secure environment.
            </span>
          )}
        </div>
        <div></div>
        <Link to="/second" className="link-container">
        <button className="btn-start">
          
            Start assessment
          
        </button></Link>
        <div className="line"></div>
        <img src={vector} className="image-1" alt="" />
        <div className="disc1">
          Do you want to join hands with the world's most competent <br />
          <span>Cybersecurity professionals?</span>
        </div>
        <div className="question-home">
          Let's join hands to solve the most challenging problems in Cyberspace{" "}
          <br />
          Please sign up if you want to be a part of the one Cyber community
        </div>

        <Link to="/apply" className="link-container">
        <button className="btn-apply">
            Apply Now
           </button>
        </Link>

      </div>
    </div>
  );
}

export default Home;
