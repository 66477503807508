import React from "react";
import "../style/SecondPage.css";
import { Link } from "react-router-dom";

export default function SecondPage() {
  const handleClick = () => {
    window.location.pathname = "/";
  };

  return (
    <div className="bg">
      <div className="box-se">
        <div className="se-li">
          
          <div className="back"> <Link to="/" className="back-arrow">←</Link></div>
          <span onClick={handleClick} className="close">
            &times;
          </span>
        </div>
        <div className="inside">
          <div className="head" style={{ textAlign: "center" }}>
            <h3>Cybersecurity Assessment</h3> 
          </div>
          <div className="disc" style={{ textAlign: "center" }}>
            This is a condensed set of cyber security assessment questions prepared for organizations to do a quick security health check in less than 10 minutes
          </div>
          <div className="sub-head">Ready?</div>
          <div className="btn-secondpage">
            <Link to="/question" className="link-container">
            <button className="btn-start">
              
                Get Started
             
            </button>
             </Link>
          </div>
        </div>
      </div>
    </div>
  );
}
